<template>
  <div id="app">
    <Header/>
    <Download/>
    <About/>
    <Customers/>
    <Partners/>
    <Merchants/>
    <NewsRoom/>
    <Contact/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Download from '@/components/Download.vue'
import About from '@/components/About.vue'
import Customers from '@/components/Customers.vue'
import Partners from '@/components/Partners.vue'
import Merchants from '@/components/Merchants.vue'
import NewsRoom from '@/components/NewsRoom.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'App',
  components: {
    Header,
    Download,
    About,
    Customers,
    Partners,
    Merchants,
    NewsRoom,
    Contact
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0
}
html, body{
  width: 100%;
  height: 100%;
}
#app {
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 1040px;
  margin: 0 auto;
}
</style>
