<template>
	<div id="merchants">
		<div :class="['partner', 'reverse']" v-for="(p,i) in partners" :key="i">
			<VideoSwiper theme="blue" :videos="videos" style="background-color: #FBFBFB;"></VideoSwiper>
			<div class="content">
				<div class="title">{{p.title}}</div>
				<div class="sub-title">{{p.subTitle}}</div>
				<div class="cards">
					<div class="card" v-for="(c,k) in p.cards" :key="k">
						<img class="icon" :src="c.icon" />
						<div class="name">{{c.name}}</div>
						<div class="desc">{{c.desc}}</div>
					</div>
				</div>
				<a :href="p.btnLink">
					<div class="link-button">{{p.btnName}}</div>
				</a>
			</div>
		</div>
		<div class="brand">
			<div class="brand-logos">
				<img src="../assets/brand1.png" alt="">
				<img src="../assets/brand2.png" alt="">
				<img class="brand3" src="../assets/brand3.png" alt="">
				<img src="../assets/brand4.png" alt="">
				<img src="../assets/brand5.png" alt="">
			</div>
			<div class="brand-swiper">
				<img class="left-button" src="../assets/arrow-left.png" @click="slidePrev" />
				<swiper class="swiper" ref="mySwiper" :options="swiperOptions">
					<swiper-slide class="swiper-item" v-for="(bg, idx) in brands" :key="idx">
						<div class="card" v-for="(b,i) in bg" :key="i">
							<img class="logo" :src="b.logo">
							<div class="title">{{b.title}}</div>
							<div class="content">{{b.content}}</div>
							<div class="user">{{b.user}}</div>
						</div>
					</swiper-slide>
				</swiper>
				<img class="right-button" src="../assets/arrow-right.png" @click="slideNext" />
			</div>
		</div>
		<div class="investors">
			<div class="content">
				<div class="title">INVESTORS</div>
				<div class="pics">
					<img src="../assets/in1.png" alt="" class="pic in1">
					<img src="../assets/in2.png" alt="" class="pic in2">
					<img src="../assets/in3.png" alt="" class="pic in3">
					<img src="../assets/in4.png" alt="" class="pic in4">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VideoSwiper from './VideoSwiper.vue'
	export default {
		components: {
			VideoSwiper
		},
		data() {
			return {
				swiperOptions: {
					pagination: {
						el: '.swiper-pagination'
					},
					loop: true
				},
				videos: [
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/merchant1.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/merchant2.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/merchant3.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/merchant4.mp4',
				],
				partners: [{
					title: 'MERCHANTS',
					subTitle: 'A game changer',
					cards: [{
						icon: require('../assets/light-blue.png'),
						name: 'Grow Your Business',
						desc: 'Reach your next customers at competitive prices'
					}, {
						icon: require('../assets/heart-cart-blue.png'),
						name: 'Build  Brand Loyalty',
						desc: 'Engaging features that develop strong customer relationship'
					}],
					btnName: 'Join as a Merchant',
					btnLink: 'https://www.webuy.com.sg/merchants'
				}],
				brands: [
					[{
							logo: require('../assets/brand4.png'),
							title: 'New heights',
							content: 'Our customers keep coming back for more.',
							user: 'Sandra Tan, Marketing Director'
						},
						{
							logo: require('../assets/brand5.png'),
							title: 'An absolute delight',
							content: 'We’ve reached more customers than ever before! ',
							user: 'Max Lee, CEO'
						}
					]
				]
			}
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			}
		},
		mounted() {

		},
		methods: {
			slidePrev() {
				this.swiper.slidePrev()
			},
			slideNext() {
				this.swiper.slideNext()
			}
		}
	}
</script>

<style lang="scss" scoped>
	#merchants {
		&::before {
			content: '';
			display: block;
			height: 64px;
			margin-top: -64px;
			visibility: hidden;
		}

		.partner {
			width: 1040px;
			margin: 0 auto;
			height: 600px;
			display: flex;
			margin-bottom: 60px;

			&.reverse {
				flex-flow: row-reverse;
			}

			.content {
				width: 50%;
				padding: 40px;
				box-sizing: border-box;

				.title {
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
					letter-spacing: 4px;
					color: #02283F;
				}

				.sub-title {
					font-weight: bold;
					font-size: 32px;
					line-height: 48px;
					color: #0060FF;
					margin: 40px 0;
				}

				.cards {
					display: flex;
					justify-content: space-between;

					.card {
						width: 200px;

						.icon {
							width: 40px;
							height: 40px;
						}

						.name {
							font-weight: 600;
							font-size: 24px;
							line-height: 32px;
							color: #02283F;
							margin: 10px 0;
						}

						.desc {
							font-weight: 500;
							font-size: 14px;
							line-height: 21px;
							color: #5C5C5C;
						}
					}
				}

				a {
					text-decoration: none;
				}

				.link-button {
					width: 200px;
					height: 40px;
					background: #0060FF;
					border-radius: 14px;
					margin-top: 40px;
					font-weight: 500;
					font-size: 14px;
					line-height: 40px;
					color: #fff;
					text-align: center;
				}
			}
		}

		.brand {
			width: 1040px;
			margin: 0 auto;

			.brand-logos {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 60px;

				img {
					width: 130px;
					height: 130px;

					&.brand3 {
						width: 160px;
						height: 90px;
					}
				}
			}

			.brand-swiper {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left-button {
					width: 26px;
				}

				.swiper {
					.swiper-item {
						display: flex;
						justify-content: center;

						.card {
							width: 400px;
							height: 232px;
							background: #FBFBFB;
							box-sizing: border-box;
							padding: 16px 36px;
							margin-right: 20px;

							.logo {
								width: 60px;
								height: 60px;
							}

							.title {
								font-weight: bold;
								font-size: 32px;
								line-height: 48px;
								color: #5C5C5C;
							}

							.content {
								font-weight: 500;
								font-size: 14px;
								line-height: 21px;
								color: #02283F;
							}

							.user {
								font-weight: normal;
								font-size: 12px;
								line-height: 18px;
								color: #5C5C5C;
							}
						}
					}
				}

				.left-button {
					cursor: pointer;
				}

				.right-button {
					width: 26px;
					cursor: pointer;
				}
			}
		}

		.investors {
			height: 200px;
			background: #FBFBFB;
			padding-top: 30px;
			margin-top: 60px;

			.content {
				width: 1040px;
				margin: 0 auto;
			}

			.title {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				text-align: center;
				letter-spacing: 4px;
				color: #02283F;
				margin: 30px 0;
			}

			.pics {
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.pic {}

				.in1 {
					width: 79px;
					height: 60px;
				}

				.in2 {
					width: 174px;
					height: 47px;
				}

				.in3 {
					width: 246px;
					height: 25px;
				}

				.in4 {
					width: 193px;
					height: 38px;
				}
			}
		}
	}
</style>
