<template>
  <div class="header">
    <div class="content">
      <div class="logo">
        <img src="../assets/Bear.png" alt="logo" class="icon">
        <img src="../assets/name.svg" alt="name" class="name">
      </div>
      <nav class="navs">
        <a class="nav" 
          v-for="link in links" 
          :key="link.name"
          :href="link.path">{{link.name}}</a>
      </nav>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        links: [{
          name: 'Travel',
          path: 'https://www.webuysg.com/travel'
        },{
          name: 'Download',
          path: '#download'
        },{
          name: 'About',
          path: '#about'
        },{
          name: 'Customers',
          path: '#customers'
        },{
          name: 'Partners',
          path: '#partners'
        },{
          name: 'Merchants',
          path: '#merchants'
        },{
          name: 'Newsroom',
          path: '#newsroom'
        },{
          name: 'Contact',
          path: '#contact'
        }]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
  }
  .content{
    display: flex;
    align-items: center;
    width: 1040px;
    margin: 0 auto;
    height: 64px;
    justify-content: space-between;
    line-height: 64px;
    .logo{
      display: flex;
      margin-left: 20px;
      align-items: center;
      .icon{
        width: 40px;
        height: 40px;
        margin: 0 20px;
      }
      .name{
        width: 94px;
        height: 20px;
      }
    }
    .navs{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      .nav{
        height: 100%;
        padding: 0 20px;
        color: #02283F;
        text-decoration: none;
      }
    }
  }
</style>