<template>
	<div id="customers">
		<div class="customer">
			<div class="mask"></div>
			<div class="container">
				<VideoSwiper theme="white" :videos="videos"></VideoSwiper>
				<div class="content">
					<div class="title">{{content.title}}</div>
					<div class="sub-title">{{content.subTitle}}</div>
					<div class="cards">
						<div class="card" v-for="(c,k) in content.cards" :key="k">
							<img class="icon" :src="c.icon" />
							<div class="name">{{c.name}}</div>
							<div class="desc">{{c.desc}}</div>
						</div>
					</div>
					<Form class="form"></Form>
				</div>
			</div>
		</div>
		<div class="comments">
			<div class="title">WHAT CUSTOMERS ARE SAYING</div>
			<div class="sub-title">"My Favourite App"</div>
			<div class="user">- Satisfied Webuyer</div>
			<div class="list">
				<div class="item" v-for="(item, idx) in comments" :key="idx">
					<div class="name">{{item.name}}</div>
					<div class="stars">
						<img class="star" src="../assets/Star.png" alt="" v-for="(i,j) in item.star" :key="j">
					</div>
					<img :src="item.pic" class="pic" />
					<div class="goods">{{item.goods}}</div>
					<div class="content">{{item.content}}</div>
					<div class="type">{{item.type}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Form from './Form.vue'
	import VideoSwiper from './VideoSwiper.vue'
	export default {
		components: {
			Form,
			VideoSwiper
		},
		data() {
			return {
				videos: [
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/customer1.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/customer2.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/customer3.mp4',
          'https://webuy-pc.oss-ap-southeast-1.aliyuncs.com/video/customer4.mp4',
        ],
				content: {
					title: 'CUSTOMERS',
					subTitle: 'Shopping made easy',
					cards: [{
						icon: require('../assets/happy.png'),
						name: 'Social',
						desc: 'Share honest reviews, video your experience - shopping online has never been more authentic!'
					}, {
						icon: require('../assets/quality.png'),
						name: 'Variety & Quality',
						desc: 'Over 1,000 products, 40 brands, and 20 countries. Quality guaranteed or 100% refund!'
					}]
				},
				comments: [{
					name: 'Kar***',
					star: 5,
					pic: require('../assets/food1.png'),
					goods: '[Famous Kulai YL Dim Sum Restaurant] Bao Series (5 Flavors)',
					content: 'Great product and much cheaper than wet market.',
					type: 'Bedok Groupbuy'
				}, {
					name: 'DY***',
					star: 5,
					pic: require('../assets/food2.png'),
					goods: 'Fresh Red Snapper Steak Cut (180 - 220g) X 3pcs',
					content: 'Taste good, individual vacuum packed.',
					type: 'Punggol Groupbuy'
				}, {
					name: 'Joe***',
					star: 5,
					pic: require('../assets/food3.png'),
					goods: 'Frozen Choice Sirloin Steak USA (250g x 2 pieces)',
					content: 'Steak is juicy, tender and has a minimum amount of fat',
					type: 'Jurong West Groupbuy'
				}, {
					name: 'Cin***',
					star: 5,
					pic: require('../assets/food4.png'),
					goods: 'Jollibean ($5 Cash Voucher)',
					content: 'Highly recommended! great deals! Easy to use',
					type: 'Tiong Bahru Groupbuy'
				}, {
					name: 'Mom***',
					star: 5,
					pic: require('../assets/food5.png'),
					goods: '[Lunch & Dinner] TungLok - Roast Irish Duck (Best Duck in the World) FDP',
					content: 'Yummy juicy duck with crispy skin. very good buy',
					type: 'Seng Kang Groupbuy'
				}, {
					name: 'Jes***',
					star: 5,
					pic: require('../assets/food6.png'),
					goods: 'Pahang WEBUY MSW Durian 400g/box',
					content: 'Beautiful yellow flesh! Great Quality!',
					type: 'Hougang Groupbuy'
				}]
			}
		}
	}
</script>

<style lang="scss" scoped>
	#customers {
		&::before {
			content: '';
			display: block;
			height: 64px;
			margin-top: -64px;
			visibility: hidden;
		}

		margin-bottom: 90px;

		.customer {
			position: relative;
			width: 100%;
			height: 720px;
			background-image: url('../assets/bg.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.mask {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba(0, 0, 0, .4);
			}

			.container {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 1040px;
				margin: 0 auto;
				height: 600px;
				display: flex;
				transform: translate(-50%, -50%);
				flex-flow: row-reverse;

				.video-swiper {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: space-around;

					.button {
						width: 26px;
						height: 42px;
					}

					.swiper {
						width: 220px;
						height: 462px;
						background: #000;
						filter: drop-shadow(0px 40px 80px rgba(0, 0, 0, 0.6));
						border-radius: 20px;
					}
				}

				.content {
					width: 50%;
					padding: 40px;
					box-sizing: border-box;

					.title {
						font-weight: 600;
						font-size: 20px;
						line-height: 30px;
						letter-spacing: 4px;
						color: #fff;
					}

					.sub-title {
						font-weight: bold;
						font-size: 32px;
						line-height: 48px;
						color: #fff;
						margin: 40px 0;
					}

					.cards {
						display: flex;
						justify-content: space-between;

						.card {
							width: 200px;

							.icon {
								width: 40px;
								height: 40px;
							}

							.name {
								font-weight: 600;
								font-size: 24px;
								line-height: 32px;
								color: #fff;
								margin: 10px 0;
							}

							.desc {
								font-weight: 500;
								font-size: 14px;
								line-height: 21px;
								color: #fff;
							}
						}
					}

					.form {
						margin-top: 40px
					}

					.link-button {
						width: 200px;
						height: 40px;
						background: #0060FF;
						border-radius: 14px;
						margin-top: 40px;
						font-weight: 500;
						font-size: 14px;
						line-height: 40px;
						color: #fff;
						text-align: center;
					}
				}
			}
		}

		.comments {
			margin-top: 60px;

			.title {
				font-weight: 600;
				font-size: 20px;
				line-height: 30px;
				text-align: center;
				letter-spacing: 4px;
				color: #02283F;
				margin-bottom: 24px;
			}

			.sub-title {
				font-weight: bold;
				font-size: 32px;
				line-height: 48px;
				text-align: center;
				color: #0060FF;
				margin-bottom: 24px;
			}

			.user {
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				color: #5C5C5C;
				margin-bottom: 30px;
			}

			.list {
				width: 1040px;
				overflow: scroll;
				white-space: nowrap;
				margin: 0 auto;
				display: flex;
				align-items: flex-start;

				.item {
					display: inline-block;
					width: 260px;
					margin-right: 40px;
					white-space: initial;

					.name {
						width: 82px;
						height: 30px;
						background: rgba(0, 96, 255, 0.1);
						border-radius: 8px;
						font-weight: 600;
						font-size: 14px;
						line-height: 30px;
						text-align: center;
						color: #0060FF;
						margin-bottom: 20px;
					}

					.stars {
						margin-bottom: 20px;

						.star {
							width: 20px;
							height: 20px;
							margin-right: 4px;
						}
					}

					.pic {
						width: 260px;
						height: 150px;
						margin-bottom: 20px;
					}

					.goods {
						font-weight: 600;
						font-size: 14px;
						line-height: 21px;
						color: #02283F;
						margin-bottom: 20px;
					}

					.content {
						font-weight: bold;
						font-size: 32px;
						line-height: 48px;
						color: #5C5C5C;
						margin-bottom: 20px;
					}

					.type {
						font-size: 12px;
						line-height: 18px;
						color: #5C5C5C;
						margin-bottom: 20px;
					}
				}
			}
		}
	}
</style>
