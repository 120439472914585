<template>
	<div class="msg-form">
		<div class="form">
			<input type="text" placeholder="Phone Number" v-model="phone" @focus="handleFocus">
			<div class="button" @click="handleClick" v-if="count === 60">Text Me a Download Link</div>
			<div class="button disable" v-else>Resend after {{count}}s </div>
		</div>
		<div class="errors" v-if="errors">{{errors}}</div>
		<div class="tips" v-if="showTips">By clicking “Text me a download link”, you accept the Terms of Service</div>
	</div>
</template>

<script>
	export default {
		props: ['showTips'],
		data() {
			return {
				phone: '',
				count: 60,
				errors: ''
			}
		},
		methods: {
			handleClick() {
				if (!this.phone) {
					return
				} else {
					if (this.verify(this.phone)) {
						this.sendMsg()
						this.countDown()
					} else {
						this.errors = 'Incorrect phone number'
					}
				}
			},
			countDown() {
				this.count--
				if (this.count) {
					setTimeout(() => {
						this.countDown()
					}, 1000)
				} else {
					this.count = 60
				}
			},
			handleFocus() {
				this.errors = ''
			},
			verify(phone) {
				return /^(6|8|9)\d{7}$/.test(phone)
			},
			sendMsg() {
				// 开发环境
				// fetch('https://dev2-apimini.webuy.ren/wb_admin/api/send/sendMessage', {
				// 测试环境
				// fetch('https://dev-apimini.webuytw.com/wb_admin/api/send/sendMessage', {
				// 线上api
				fetch('https://apimini.webuyvn.com/wb_admin/api/send/sendMessage', {
						method: 'POST',
						body: JSON.stringify({
							phoneNumber: this.phone,
							countryCode: '65'
						}),
						headers: {
							'Content-type': 'application/json'
						}
					}).then(res => res.json())
					.then(res => {
						console.log(res)
						if (res.code !== 2000) {
							this.errors = res.data
						}
					}).catch(err => {
						console.log(err)
						this.errors = 'Please try again later'
					})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.msg-form {
		.form {
			display: flex;

			input {
				width: 200px;
				height: 40px;
				background: #FBFBFB;
				border-radius: 14px;
				margin-right: 6px;
				outline: none;
				border: none;
				padding: 10px 12px;
				box-sizing: border-box;
			}

			.button {
				width: 200px;
				height: 40px;
				background: #0060FF;
				border-radius: 14px;
				font-weight: 500;
				font-size: 14px;
				line-height: 40px;
				text-align: center;
				color: #fff;
				cursor: pointer;

				&.disable {
					color: #ccc;
				}
			}
		}

		.errors {
			color: red;
			margin-top: 4px;
		}

		.tips {
			font-size: 10px;
			line-height: 15px;
			color: #E5E5E5;
			margin-top: 10px;
		}
	}
</style>
