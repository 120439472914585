<template>
  <div id="newsroom">
    <div class="title">NEWSROOM</div>
    <div class="history">
      <div class="time-shaft">
        <div  :class="[{'active': selectDate === date},'item']" v-for="(date, idx) in dates" :key="date">
          <div class="top" @click="select(date)">
            <div class="point"></div>
            <div class="date">{{date}}</div>
          </div>
          <div class="line" v-if="idx !== dates.length-1"></div>
        </div>
      </div>
      <div class="news">
        <div class="news-item" 
        v-for="newsItem in newsArr" :key="newsItem"
        v-html="newsItem"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        selectDate: 'May 2021',
        dates: [
          'May 2021',
          'Mar 2021',
          'Feb 2021',
          'Dec 2020',
          'Oct 2020',
          'Aug 2020',
          'Feb 2020'
        ],
        news: {
          'May 2021': [
            `Social Commerce Captures Online Markets in Asia<a class="alink" href="https://www.statista.com/chart/24825/social-commerce-country-comparison/">statista.com</a>`,
            `Popular in China, the group buying fever is reaching Singapore<a href="https://kr-asia.com/popular-in-china-the-group-buying-fever-is-reaching-singapore">statista.com</a>`,
            `China's group buying trend has hit S’pore’s shores, do the community leaders profit from it?<a href="https://vulcanpost.com/745237/group-buying-community-leaders-singapore/">statista.com</a>`,
          ],
          'Mar 2021': [
            `A Guide To Group Buying In S'pore: How Does It Work And How Much Can You Really Save?<a href="https://vulcanpost.com/738958/guide-to-group-buying-in-singapore/">vulcanpost.com</a>`
          ],
          'Feb 2021': [
            `Webuy is Southeast Asia’s breakout social commerce service: Startup Stories <a href="https://kr-asia.com/webuy-is-southeast-asias-breakout-social-commerce-service-startup-stories">kr-asia.com</a>`
          ],
          'Dec 2020': [
            `How WEBUY Founder Is Bringing China’s Multi-Billion Community Group Buying Market To S’pore <a href="https://vulcanpost.com/727485/webuy-group-buying-app-singapore/">vulcanpost.com</a>`
          ],
          'Oct 2020': [
            `WEBUY lands US$6M Series A to take its social e-commerce biz into Vietnam, Philippines <a href="https://e27.co/webuy-lands-us6m-series-a-to-take-its-social-e-commerce-biz-into-vietnam-philippines-20201029/">e27.co</a>`,
            `Singapore social ecommerce startup WeBuy banks $6m in Wavemaker-led round <a href="https://www.techinasia.com/singapore-social-ecommerce-startup-webuy-banks-6m-wavemakerled">techinasia.com</a>`,
            `Social e-commerce platform Webuy bags US$6m in Series A funding <a href="https://www.businesstimes.com.sg/garage/social-e-commerce-platform-webuy-bags-us6m-in-series-a-funding">businesstimes.com</a>`,
            `Social Commerce Platform WeBuy raises US$6 mil Series A for SEA Expansion <a href="https://www.digitalnewsasia.com/startups/social-commerce-platform-webuy-raises-us6-mil-series-sea-expansion">digitalnewsasia.com</a>`,
            `SG's WEBUY Lands US$6M Series A to Take Its Social E-Commerce Biz into Vietnam, Philippines <a href="https://www.volanews.com/portal/article/index/id/8658/SG%27s+WEBUY+Lands+US%246M+Series+A+to+Take+Its+Social+E-Commerce+Biz+into+Vietnam%2C+Philippines/language/en.html">volanews.com</a>`
          ],
          'Aug 2020': [
            `新加坡首届吃货节，全球美食，折扣多多 <a href="https://www.yan.sg/meishijieduoduo/">yan.sg</a>`
          ],
          'Feb 2020': [
            `ezbuy co-founder's new startup WEBUY raises multi-million dollars in first funding led by Rocket Internet's VC arm <a href="https://e27.co/ezbuy-co-founders-new-startup-webuy-raises-multi-million-dollars-in-first-funding-led-by-rocket-internets-vc-arm-20200205/">e27.co</a>`,
            `WEBUY Seeks Social and Sales Talent in SE Asia <a href="https://indvstrvs.org/webuy-seeks-social-and-sales-talent-in-se-asia/">indvstrvs.com</a>`,
            `After social ecommerce’s success in China, Webuy tests the waters in Southeast Asia <a href="https://www.techinasia.com/webuy-brings-social-ecommerce-southeast-asia-success-china">techinasia.com</a>`,
            `专访 |东南亚社交电商WEBUY，是如何吸引妈妈粉带货的？<a href="https://www.sohu.com/a/376478742_100037570">sohu.com</a>`
          ]
        }
      }
    },
    computed:{
      newsArr(){
        return this.news[this.selectDate]
      }
    },
    methods: {
      select(date){
        this.selectDate = date
      }
    }
  }
</script>

<style lang="scss" scoped>
  #newsroom{
    &::before {
      content: '';
      display: block;
      height: 64px;
      margin-top: -64px;
      visibility: hidden;
    }
    width: 1040px;
    margin: 0 auto;
    margin-bottom: 60px;
    .title{
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 4px;
      color: #02283F;
      margin: 30px 0;
    }
    .history{
      display: flex;
      justify-content: center;
      .time-shaft{
        .item{
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #C4C4C4;
          padding: 0 12px 0 24px;
          .top{
            display: flex;
            align-items: center;
            cursor: pointer;
            .point{
              background: #C4C4C4;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              margin-right: 20px;
            }
          }
          .line{
              width: 1px;
              background: #C4C4C4;
              height: 50px;
              margin-left: 6px;
          }
          &.active{
            color: #0060FF;
            .top{
              .point{
                background: #0060FF;
              }
            }
            .line{
              background: #0060FF;
            }
          }
        }
      }
      .news{
        width: 780px;
        padding: 0 60px;
        box-sizing: border-box;
        .news-item{
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #02283F;
          margin-bottom: 40px;
          /deep/ a{
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #0060FF;
          }
        }
      }
    }
  }
</style>