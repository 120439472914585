<template>
  <div id="contact">
    <div class="email">
      <img src="../assets/contact-icon.png" alt="" class="icon">
      <span class="msg">Want to work with us？Drop an email to <a href="mailto:hr@webuy.global">hr@webuy.global</a></span>
    </div>
    <div class="footer">
      <img class="logo" src="../assets/w.png" alt="">
      <span class="copy">©2021 WEBUY</span>
      <nav>
        <span>Terms of Service</span>
        <span>Privacy Policy</span>
        <span>Cookie Settings</span>
      </nav>
      <div class="icons">
        <a href="https://www.facebook.com/webuysingapore/"><img src="../assets/icon-facebook.png" alt=""></a>
        <a href="https://www.instagram.com/webuy_sg/"><img src="../assets/icon-ins.png" alt=""></a>
        <a href="https://www.linkedin.com/company/webuysg"><img src="../assets/icon-lin.png" alt=""></a>   
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  #contact{
    &::before {
      content: '';
      display: block;
      height: 64px;
      margin-top: -64px;
      visibility: hidden;
    }
    .email{
      width: 100%;
      height: 200px;
      background-color: #0060FF;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        width: 80px;
        height: 80px;
        margin-right: 45px;
      }
      .msg{
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        a{
          color: #fff;
        }
      }
    }
    .footer{
      display: flex;
      width: 1040px;
      margin: 0 auto;
      height: 160px;
      align-items: center;
      .logo{
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      .copy{
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        margin-right: 90px;
      }
      nav{
        flex: 1;
        span{
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          margin-right: 40px;
        }
      }
      .icons{
        img{
          width: 20px;
          height: 20px;
          margin-right: 22px;
        }
      }
    }
  }
</style>